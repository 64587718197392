import NavbarItem from "@/components/Global/NavbarItem"

const NavbarListMobile = () => {
  return (
    <div className="flex-grow rounded-[40px] bg-white py-11 shadow-lg">
      <div className="flex flex-col-reverse items-center gap-9 px-7">
        <NavbarItem
          className="grid w-full justify-center border-b border-slate-800 pb-9"
          href="/free-screening"
          label="Free Screening"
        />
        <NavbarItem
          className="grid w-full justify-center border-b border-slate-800 pb-9"
          href="/about"
          label="About"
        />
        <NavbarItem
          className="grid w-full justify-center border-b border-slate-800 pb-9"
          href="/employers"
          label="Employers"
        />
        <NavbarItem
          className="grid w-full justify-center border-b border-slate-800 pb-9"
          href="/therapists"
          label="Therapists"
        />
        <NavbarItem
          className="grid w-full justify-center border-b border-slate-800 pb-9"
          href="/contact-us"
          label="Contact Us"
        />
      </div>
    </div>
  )
}

export default NavbarListMobile
