import AmariLogo from "@/assets/img/amari_logo.svg"

const NavbarLogo = () => {
  return (
    <div className="w-20 sm:w-[121px]">
      <a href="/">
        <img src={AmariLogo.src} alt="Amari logo" />
      </a>
    </div>
  )
}

export default NavbarLogo
