import { cn } from "@/lib/utils/styling"
import { currentUrlPath } from "@/stores/navStore"
import { useStore } from "@nanostores/react"

interface NavbarItemProps {
  className?: string
  href: string
  label: string
  newTab?: boolean
}

const NavbarItem = (props: NavbarItemProps) => {
  const { className, href, label, newTab = false } = props

  const $currentUrlPath = useStore(currentUrlPath)
  const isActive = $currentUrlPath.includes(href)

  const linkClasses = isActive ? "text-brand-accent font-bold" : "text-slate-800"

  return (
    <div className={cn("inline-flex items-center justify-start", className)}>
      <a
        href={href}
        className={cn("text-center font-sans text-sm leading-tight", linkClasses)}
        target={newTab ? "_blank" : undefined}
        rel={newTab ? "noreferrer noopener" : undefined}
      >
        {label}
      </a>
    </div>
  )
}

export default NavbarItem
