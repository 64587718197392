import NavbarItem from "@/components/Global/NavbarItem"

const NavbarList = () => {
  return (
    <div className="inline-flex items-center justify-start gap-6">
      <NavbarItem href="/free-screening" label="Free Screening" />
      <NavbarItem href="/about" label="About" />
      <NavbarItem href="/employers" label="Employers" />
      <NavbarItem href="/therapists" label="Therapists" />
      <NavbarItem href="/contact-us" label="Contact Us" />
    </div>
  )
}

export default NavbarList
