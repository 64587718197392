import NavbarAuth from "@/components/Global/NavbarAuth"
import NavbarList from "@/components/Global/NavbarList"
import NavbarListMobile from "@/components/Global/NavbarListMobile"
import NavbarLogo from "@/components/Global/NavbarLogo"
import { cn } from "@/lib/utils/styling"
import { currentUrlPath } from "@/stores/navStore"
import { menuVisible } from "@/stores/navStore"
import { useStore } from "@nanostores/react"
import { MenuIcon, XIcon } from "lucide-react"
import { useEffect } from "react"

interface NavbarProps {
  currentPath: string
}
const Navbar = (props: NavbarProps) => {
  const $menuVisible = useStore(menuVisible)

  useEffect(() => {
    if ($menuVisible) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [$menuVisible])

  const { currentPath } = props
  currentUrlPath.set(currentPath)

  const openMenuClasses = $menuVisible ? "fixed h-screen z-10 overflow-auto" : "rounded-full"

  return (
    <div className={cn("", { "p-3": !$menuVisible })}>
      <div className={cn("border-1 w-full bg-navbar bg-cover bg-repeat-round", openMenuClasses)}>
        <div className="flex  px-8  sm:justify-center">
          <div className="flex w-full max-w-7xl items-center justify-between py-7 sm:mx-12 sm:gap-10 sm:py-16">
            <NavbarLogo />
            <div className="hidden lg:block">
              <NavbarList />
            </div>
            <div className="hidden lg:block">
              <NavbarAuth />
            </div>
            <div className="block lg:hidden">
              <button onClick={() => menuVisible.set(!$menuVisible)}>
                {$menuVisible ? <XIcon /> : <MenuIcon />}
              </button>
            </div>
          </div>
        </div>
        {$menuVisible && (
          <div className="flex w-full flex-col justify-center gap-10 border-t border-t-violet-100 p-4">
            <NavbarListMobile />
            <div className="mb-[10vh] self-center">
              <NavbarAuth />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar
