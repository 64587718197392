import { Button } from "@/components/Shadcn/Ui/button"
import { ChevronRightIcon } from "lucide-react"

const NavbarAuth = () => {
  return (
    <div className="flex items-center justify-start gap-3">
      <Button
        asChild
        className="w-[120px] rounded-3xl border border-slate-800  bg-transparent px-5 py-6 hover:border-black hover:bg-transparent hover:text-black hover:shadow-xl sm:w-auto"
      >
        <a
          href="https://amarihealth.simplybook.me/v2/#client/sign-in"
          className="text-sm font-medium leading-snug text-slate-800"
        >
          Login
        </a>
      </Button>

      <Button
        asChild
        className="w-[120px] rounded-3xl bg-white px-5 py-6 shadow-md hover:bg-gray-100 hover:shadow-xl sm:w-auto"
      >
        <a
          href="https://amarihealth.simplybook.me/v2/#book"
          className="flex items-center justify-between gap-1 text-sm font-bold leading-snug text-slate-800 "
        >
          Book now
          <ChevronRightIcon />
        </a>
      </Button>
    </div>
  )
}

export default NavbarAuth
